import React, { useState } from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import useScript from "../service/useScript"

const Outpatient = ({ data: { allNodeTemplatePages: { edges } } }) => {

  const outPatient = edges[0]
  const metaTags = outPatient.node?.metatag_normalized.length > 0 ? outPatient?.node?.metatag_normalized : [];
  const canonicalUrl = outPatient?.node?.metatags_on_page || {}

  const data = outPatient?.node?.relationships?.field_component_type;

  const bannerData = data[0];
  const bannerTitle = bannerData?.title || "";
  const bannerSubtitle = bannerData?.subtitle || "";
  const bannerImage = bannerData?.relationships?.bannerImage?.uri?.url;
  const bannerImageAlt = bannerData?.bannerImage?.alt.length > 0 ? bannerData?.bannerImage?.alt : "Hinduja Hospital "

  // const vistingData = data[1];
  // const title = vistingData?.field_title;
  // const description = vistingData?.text?.processed;


  const accordianData = data[2];
  const accordian = accordianData?.relationships?.components;

  useScript("/assets/js/outpatient.js")

  const scrollView = (id) => {
    if (id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth", block: "center" })
    }

  }


  return (
    <Layout>
      <Meta
        files={{
          js: [],
          css: ["/assets/css/services.css", "/assets/css/accessibility.css"],
        }}
        tags={{metaTags,canonicalUrl}}
      />
      <main className="innerpage pt-first-section">
        <section className="inner_common_banner">
          <img src={bannerImage} alt={bannerImageAlt} className="banner_img lazyload" />
          <div className="banner_content text_left text_dark">
            <div className="container">
              <div className="bannerDescription">
                <h1>{bannerTitle.length > 0 ? bannerTitle : "Your Clinic Visit - Out-Patient"}</h1>
                <p className="subhead" dangerouslySetInnerHTML={{ __html: bannerSubtitle.length > 0 && bannerSubtitle }} />
              </div>
            </div>
          </div>
        </section>
        <section className="speciality_section section-bg section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="accordion_container">
                  <div id="accordion" role="tablist" className="common_accordion specialty_accordion">
                    {
                      accordian.map((el, index) => {
                        if (el.__typename === "paragraph__image_with_title_and_description") {

                          return (
                            <div className="card" key={index}>
                              <div className="card-header" role="tab" id={`heading_inpatient0${index + 1}`}>
                                <h5 className="mb-0">
                                  <a className="" data-toggle="collapse" href={`#outpatient_0${index + 1}`} aria-expanded="false" id={index + 1}>
                                    {el?.title}
                                  </a>
                                </h5>
                              </div>
                              <div id={`outpatient_0${index + 1}`} className="collapse show" role="tabpanel" aria-labelledby="heading_inpatient01" data-parent="#accordion">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <figure>
                                        <img src={el?.relationships?.image?.uri?.url} alt={el?.image?.alt} />
                                      </figure>
                                    </div>
                                    <div className="col-md-6" dangerouslySetInnerHTML={{ __html: el?.description?.processed }} />


                                  </div>
                                  <div dangerouslySetInnerHTML={{ __html: el?.field_text?.processed }} />
                                  <figure>
                                    <img src={el?.relationships?.image?.uri?.url} alt={el?.image?.alt} />
                                  </figure>

                                </div>
                              </div>
                            </div>
                          )
                        } else {
                          return (
                            <div className="card" key={index}>
                              <div className="card-header" role="tab" id={`heading_inpatient0${index + 1}`}>
                                <h5 className="mb-0">
                                  <a className="collapsed" data-toggle="collapse" href={`#outpatient_0${index + 1}`} aria-expanded="false" id={index + 1} >
                                    {el?.field_title}
                                  </a>
                                </h5>
                              </div>
                              <div id={`outpatient_0${index + 1}`} className="collapse" role="tabpanel" aria-labelledby="heading_inpatient02" data-parent="#accordion">
                                <div className="card-body" dangerouslySetInnerHTML={{ __html: el?.text?.processed }}>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })

                    }

                  </div>
                </div>
              </div>
              {/* <div className="col-md-4" style={{
                                background: "#fff",
                                borderRadius: "20px",
                                boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.15)",
                                padding: "20px",
                                maxHeight: "400px"
                            }}>
                                <div className="visiting_hours_box">
                                    <h2 className="section-heading">{title.length ? title : "heelo"}</h2>
                                    <div dangerouslySetInnerHTML={{ __html: description }} />
                                </div>
                            </div> */}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
query patientCareOutpatient {
    allNodeTemplatePages(filter: {path: {alias: {regex: "/out-patient$/"}}}) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          metatag_normalized {
            attributes {
              content
              name
              property
            }
          }
          metatags_on_page: field_meta_tags{
            canonical_url
          }
          relationships {
            field_component_type {
              __typename
              ...ParagraphBanner
              ...ParagraphTitleAndDescription
              ...ParagraphSectionOutPatient
            }
          }
        }
      }
    }
  }
  
  fragment ParagraphSectionOutPatient on paragraph__section {
    id
    name: field_name
    relationships {
      components: field_components {
        __typename
        ...ParagraphImageWithTitleAndDescriptionOutPatient
        ...ParagraphTitleAndDescriptionOutPatient
      }
    }
  }
  
  fragment ParagraphTitleAndDescriptionOutPatient on paragraph__title_and_description {
    id
    field_title
    text: field_description {
      processed
    }
    field_cta_link {
      title
      uri
    }
  }
  
  fragment ParagraphImageWithTitleAndDescriptionOutPatient on paragraph__image_with_title_and_description {
    id
    title: field_title
    description: field_content {
      processed
    }
    image: field_image {
      alt
    }
    alignment: field_alignment
    relationships {
      image: field_image {
        id
        uri {
          value
          url
        }
      }
    }
    field_text {
      processed
    }
  }`


export default Outpatient